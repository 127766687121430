import React from 'react';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { Helmet } from 'react-helmet';
import Header from './header';
import Footer from './footer';

const pageStyles = {
  fontFamily: 'Lato, -apple-system, Roboto, sans-serif, serif',
  position: 'relative',
};

const mainStyles = {
  color: '#222',
  backgroundColor: '#F0F0F0',
  minHeight: `calc(100% - 105px)`,
};

const Layout = ({ breakpoints, children }) => {
  return (
    <main className="page" style={pageStyles}>
      <Helmet htmlAttributes={{ lang: 'en-US' }} />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato&display=swap"
        rel="stylesheet"
      />
      <Header />
      <div style={mainStyles}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: 1400,
            position: 'relative',
            margin: 'auto',
            paddingLeft: '4%',
            paddingRight: '4%',
            paddingTop: breakpoints.isMobile ? 54 : 70,
          }}
        >
          {children}
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default withBreakpoints(Layout);
