import React, { useEffect, useState } from 'react';
import Hamburger from 'hamburger-react';
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import icon from '../images/icon.png';

// styles
const headingStyles = {
  marginTop: 0,
  marginBottom: 0,
};

const logoStyles = {
  height: 50,
  width: 50,
};

const logoStylesSmall = {
  height: 34,
  width: 34,
};

const titleStyles = {
  marginLeft: 20,
  fontSize: '1.7rem',
};

const titleStylesSmall = {
  marginLeft: 10,
  fontSize: 20,
};

// data
const links = [
  {
    text: 'About',
    href: '/',
  },
  {
    text: 'Download',
    href: '/',
  },
  {
    text: 'Moneycl Pro',
    href: '/',
  },
];

// markup
const Header = ({ breakpoints }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (!breakpoints.isMobile) setOpen(false);
  }, [breakpoints.isMobile]);

  return (
    <section>
      {!breakpoints.isMobile && (
        <nav
          style={{
            backgroundColor: '#3a7a68',
            position: 'fixed',
            width: '100%',
            height: 70,
            zIndex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              maxWidth: 1400,
              position: 'relative',
              margin: 'auto',
              paddingLeft: '4%',
              paddingRight: '4%',
            }}
          >
            <div style={headingStyles}>
              <Link
                className="no-hover"
                aria-label="home"
                style={{
                  maxWidth: 320,
                  display: 'flex',
                  alignItems: 'center',
                  margin: 10,
                  marginLeft: 20,
                  textDecoration: 'none',
                }}
                to="/"
              >
                <img alt="Moneycl Logo" style={logoStyles} src={icon} />
                <span style={titleStyles}>Moneycl</span>
              </Link>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', maxWidth: 550 }}
            >
              {links.map((link, index) => (
                <Link key={index} style={{ margin: 20 }} to={link.href}>
                  {link.text}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      )}
      {breakpoints.isMobile && (
        <>
          <nav
            style={{
              backgroundColor: '#3a7a68',
              position: 'fixed',
              width: '100%',
              height: 54,
              zIndex: 1,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: 750,
                position: 'relative',
                margin: 'auto',
                paddingLeft: '4%',
                paddingRight: '4%',
              }}
            >
              <div style={headingStyles}>
                <Link
                  className="no-text-decoration no-hover"
                  aria-label="home"
                  style={{
                    maxWidth: 320,
                    display: 'flex',
                    alignItems: 'center',
                    margin: 10,
                    textDecoration: 'none',
                  }}
                  onClick={() => setOpen(false)}
                  to="/"
                >
                  <img alt="Moneycl Logo" style={logoStylesSmall} src={icon} />
                  <span style={titleStylesSmall}>Moneycl</span>
                </Link>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 8,
                }}
              >
                <Hamburger
                  label="menu"
                  toggled={isOpen}
                  toggle={setOpen}
                  color="#fff"
                  size={25}
                />
              </div>
            </div>
            <div
              style={{
                height: 0,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#3a7a68',
                height: isOpen ? links.length * 40 : 0,
                transition: 'all 0.5s ease-in-out',
                overflow: 'hidden',
                maxWidth: 750,
                position: 'relative',
                margin: 'auto',
                paddingLeft: 'calc(10px + 4%)',
                paddingRight: '4%',
              }}
            >
              {links.map((link, index) => (
                <Link
                  onClick={() => setOpen(false)}
                  key={index}
                  style={{ margin: 10 }}
                  to={link.href}
                >
                  {link.text}
                </Link>
              ))}
            </div>
          </nav>
        </>
      )}
    </section>
  );
};

export default withBreakpoints(Header);
